<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">{{ headerText }}</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <b-field v-if="!isCreate"
            label="Angkatan:"
            custom-class="is-small">
            {{ targetDiag.angkatan }}
          </b-field>
          <b-field
            v-else
            label="Angkatan"
            custom-class="is-small"
            :type="{'is-danger': errorMap.angkatan}"
            :message="errorMap.angkatan"
          >
            <generic-select
              v-model="targetDiag.angkatan"
              apiPath="/programstudi/angkatan/options/"
              @input="validateInput('angkatan')"
            ></generic-select>
          </b-field>

          <b-field v-if="!isCreate"
            label="Diagnosis:"
            custom-class="is-small">
            {{ targetDiag.diagnosis }}
          </b-field>
          <b-field
            v-else
            label="Diagnosis"
            custom-class="is-small"
            :type="{'is-danger': errorMap.diagnosis}"
            :message="errorMap.diagnosis"
          >
            <generic-autocomplete
              apiPath="/penyakit/diagnosis"
              v-model="targetDiag.diagnosis"
              @input="validateInput('diagnosis')"
            ></generic-autocomplete>
          </b-field>

          <b-field label="Target" custom-class="is-small">
            <b-numberinput
              type="number"
              v-model="targetDiag.target"
              min="0"
              max="500"
              :controls="false"
              @input="validateInput('target')"
              required
            ></b-numberinput>
          </b-field>

          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import TargetDiagnosis from "../models/targetDiagnosis.js";


export default {
  name: "TargetDiagnosisCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue")
  },
  data() {
    this.targetDiagMdl = new TargetDiagnosis();
    this.objectMdl = this.targetDiagMdl;  // alias yg digunakan di cuMixin
    return this.targetDiagMdl.getObservables();
  },
  watch: {
    targetDiag: {
      // target kd berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.targetDiagMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>
