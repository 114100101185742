import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "targetDiag";
const modelPath = "/programstudi/targetdiagnosis/";

class TargetDiagnosis extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        diagnosis: null,
        target: null
    };
    let requiredFields = ["angkatan", "diagnosis", "target"];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default TargetDiagnosis;